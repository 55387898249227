::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

::-webkit-scrollbar-thumb {
  background: #C2C1BD; 
}

::-webkit-scrollbar-thumb:hover {
  background: #555; 
}