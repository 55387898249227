.bold {
    font-weight: bold;
    font-size: larger;
    margin-left: 0px;
    margin-right: 40px;
    text-align: justify;
}

.subtitle {
    font-size: medium;
    margin-left: 0px;
    margin-right: 40px;
    text-align: justify;
}

.image-style {
    display: flex;
    margin-left: 0px;
    margin-right: 10px;
    width: 100%;
    margin-top: 0px;
}

.work-div {
    margin: 20px;
}

.twitter-title {
    margin-top: 25px;
}

.notification.is-info{
    background-color: hsl(207, 61%, 53%, 85%);
}