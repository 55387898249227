body {
  height: 100vh;
  margin: 0;
  display: flex;
  flex-direction: column;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

header {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

footer {
  margin-top: 2.5rem;
  padding-top: 2rem !important;
  padding-bottom: 2em !important;
  bottom: 0;
}

footer > .content table td {
  border-bottom: 0
}

.container {
  max-width: max(1250px, 70%) !important;
  padding: 2rem;
}

.display-linebreak {
  white-space: pre-line;
}

.is-sticked {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.github-border {
  border: 1px solid #bfc5ca;
  border-radius: 0.5rem;
}

.tag-border {
  border: 1px solid #def;
  border-radius: 9999px !important;
  background-color: #def !important;
}

.github-title:hover {
  text-decoration: underline;
}

.icon-aligned {
  vertical-align: middle;
}

.tabs {
  color: white !important;
}

.tabs:hover {
  color: lightgray !important;
}

.selected-tab { 
  text-decoration: underline;
}

.pagination-link:hover {
  cursor: pointer;
}

.pagination-link:focus {
  outline: none !important;
  box-shadow: none !important;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  font-size: 20px;
  z-index: 5;
}

.full-width {
  width: 100% !important;
}

.navbar-brand {
  background-color: #181b21 !important;
}

.navbar-menu {
  background-color: #181b21 !important;
}

.navbar-burger {
  background-color: whitesmoke !important;
}

.navbar-burger:hover {
  background-color:lightslategray !important;
}

.navbar-burger:focus {
  outline: none !important;
  box-shadow: none !important;
}

.member-image {
  border: 1px solid black;
}

.center {
  margin: auto;
}

.no-margin-bottom {
  margin-bottom: 0;
}

.ul.a {
  list-style-type: circle;
}

.box-container {
    width: 100%; /* Ensure the container takes up full width */
    display: flex; /* Use flexbox layout */
    justify-content: center; /* Distribute space evenly between items */
}

.box-demo {
    width: 200px;
    height: 100px;
    background-color: #91EDA0;
    margin: 20px;
    text-align: center;
    line-height: 60px;
    border-radius: 6px;
    box-shadow: 0 1Em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02);
    color: #222833;
    display: block;
    padding: 1.25rem;
}

.responsive-image {
        max-width: 100%; /* Set maximum width to 100% */
        height: auto; /* Allow the height to adjust proportionally */
        display: block; /* Ensure proper rendering as a block-level element */
        margin: 0 auto; /* Center the image horizontally */
    }