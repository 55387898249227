/*
ul > li{
    list-style: disc;
    color: #3ab9a4;
    font-size: 18px;
    margin:0 0 15px 0;
  }
*/

.banner-container {
  display: flex;
  background-color: rgba(255, 255, 255, 0.75);
  flex-direction: column;
  justify-content: center;
  max-height: fit-content;
  padding: 20px;
  padding-left: 40px;
  padding-right: 40px;
  margin: 10px 0;
  text-align: center;
}

h3.subtext {
    margin-top: 10px;

}

.middle-section {
  width: 100%;
  margin-top: 0;
}

.middle-div {
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
}

.middle-images {
  display: flex;
  flex-direction: row;
  margin: auto;
  width: 100vw;
}

.middle-text {
  display: flex;
  flex-direction: column;
  margin-right: 0px;
  margin-left: 80px;
}

.bold-text {
  font-weight: bold;
}

.media {
  width: 100%;
}

.features-container {
  overflow: hidden;
  width: 100vw;
  height: 65vh;
  object-fit: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0;
  padding: 0;
}

footer {
  background-color: rgba(24,27,33, 0.95);
  color: white;
}

.footer {
  background-color: rgba(24,27,33, 0.95);
  color: white;
}

.card-image {
  background-color: transparent;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-container.section {
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin-left:0;
  margin-right: 0;
  padding: 0;
}

footer.home-footer {
  padding: 0 !important;
  margin: 0;
  height:20%;
}

.home-footer {
  padding: 0 !important;
  margin-top: auto;
  height:20%;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

.home-footer {
  width: 100%;
}

.home-footer-2 {
  width: 100%;
}

.introduction-to-tiles {
  background-color: rgba(255,255,255, 0.95);
  color: black
}

.aerial {
  width: 100%;
  height: 500px;
  object-fit: none;
  object-position: 50% 65%;
  position: relative;
}

.overlay-banner {
  position: absolute;
  background: rgba(255, 255, 255, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  top: 225px;
  left: 0;
  right: 0;
  margin: 0;
  width: 100%;
  padding: 0;
}

.banner-image {
  height: 150px;
  object-fit: scale-down;
  overflow: hidden;
  margin-bottom: 20px;
}

.wall-of-text {
  max-width: 60em;
  margin-left: auto;
  margin-right: auto;
  text-align: justify;
  padding: 1em;
}
