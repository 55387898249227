.pagination-container {
    display: flex;
    list-style-type: none;
    justify-content: center; /* Add this line to center the container */
}

.pagination-item {
    padding: 0 12px;
    height: 32px;
    text-align: center;
    margin: auto 4px;
    color: rgba(0, 0, 0, 0.87);
    display: flex;
    box-sizing: border-box;
    align-items: center;
    letter-spacing: 0.01071em;
    border-radius: 16px;
    line-height: 1.43;
    font-size: 13px;
    min-width: 32px;
}

.pagination-item.dots:hover {
    background-color: transparent;
    cursor: default;
}

.pagination-item:hover {
    background-color: rgba(0, 0, 0, 0.04);
    cursor: pointer;
}

.pagination-item.selected {
    background-color: rgba(0, 0, 0, 0.08);
}

.pagination-item .arrow::before {
    position: relative;
    /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
    content: '';
    /* By using an em scale, the arrows will size with the font */
    display: inline-block;
    width: 0.6em; /* Increase the width value */
    height: 0.6em; /* Increase the height value */
    border-right: 0.12em solid rgba(0, 0, 0, 0.87);
    border-top: 0.12em solid rgba(0, 0, 0, 0.87);
}

.pagination-item .arrow.left {
    transform: rotate(-135deg) translate(-50%);
}

.pagination-item .arrow.right {
    transform: rotate(45deg);
}

.pagination-item.disabled {
    pointer-events: none;
}

.pagination-item.disabled .arrow::before {
    border-right: 0.12em solid rgba(0, 0, 0, 0.43);
    border-top: 0.12em solid rgba(0, 0, 0, 0.43);
}

.pagination-item.disabled:hover {
    background-color: transparent;
    cursor: default;
}
