@charset "utf-8"

// Import a Google Font
@import url('https://fonts.googleapis.com/css?family=Nunito:400,700')

// Set your brand colors
$teal: #3182CE
$blue: #3182CE
$gray: #d0d1cd
$obsidian: #222833
$white: #ffffff

// Update Bulma's global variables
// 'Raleway'
$text: $obsidian
$family-sans-serif: 'Nunito', sans-serif 
$grey-dark: $gray
$grey-light: $obsidian
$primary: $teal
$link: $blue
$widescreen-enabled: false
$fullhd-enabled: false

// Update some of Bulma's component variables
$body-background-color: $white
$control-border-width: 2px
$input-border-color: transparent
$input-shadow: none
$footer-padding: 3rem 1.5rem 3rem
$footer-background-color: $grey-light

$divider-background-color: $teal
$divider-thickness: 5rem

// Import only what you need from Bulma
@import '../node_modules/bulma/bulma.sass'
@import '../node_modules/bulma-floating-button/dist/css/bulma-floating-button.sass'
@import "~@creativebulma/bulma-divider"